import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Checkbox } from "antd";
import PropTypes from 'prop-types';

import {
  editWhitelabelConfig,
  getWhitelabelConfig
} from "../../../../../../../../../services/properties-service/properties.service";
import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";

import BaseDragDropList from "../../../../../../../../../components/BaseDragDropList/BaseDragDropList";
import ModalDialog from "../../../../../../../../../components/modal/ModalDialog/ModalDialog";
import BrowseConfigurationAddNewItemModal
  from "./BrowseConfigurationAddNewItemModal/BrowseConfigurationAddNewItemModal";
import TabsComponent from '../../../../../../../../../components/TabsComponent/TabsComponent';

import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";

import './SetBrowseConfigurationModal.scss';

/* istanbul ignore file */
class SetBrowseConfigurationModal extends Component {
  state = {
    dataLoaded: false,
    activeTab: '1',
    browseConfig: [],
    headerLinks: []
  };

  modalRef = React.createRef();

  componentDidMount() {
    this.setLoading(this.props.t('SetBrowseConfigurationModal.loading'));
    const { propertyId } = this.props;

    getWhitelabelConfig(propertyId)
      .then(resp => {
        const { browse_config = [], header_links = [] } = resp?.data?.data || {};
        this.setState({
          dataLoaded: true,
          browseConfig: browse_config,
          headerLinks: header_links
        });
        this.clearLoading();
      })
      .catch(err => {
        console.log(err);
        this.clearLoading();
        displayErrorNotification({
          duration: 0,
          message: 'Loading failed.'
        });
      });
  }

  clearLoading = () => this.getModal().clearLoading();

  closeModal = () => this.getModal().closeModal();

  showEditItemModal = (item) => {
    const modal = (
      <BrowseConfigurationAddNewItemModal
        item={item}
        onItemAdd={this.onItemEdit}
        activeTab={this.state.activeTab} />
    );
    ModalController.showModal(modal);
  };

  showAddNewItemModal = () => {
    const modal = (
      <BrowseConfigurationAddNewItemModal
        onItemAdd={this.onAddNewItem}
        activeTab={this.state.activeTab} />
    );
    ModalController.showModal(modal);
  };

  generateId = () => new Date().getTime();

  getActions = () => {
    const { t } = this.props;
    return (
      <div className="Actions">
        <Button onClick={this.showAddNewItemModal}>
          {t('SetBrowseConfigurationModal.addNewItem')}
        </Button>
        <div className="Inner">
          <Button onClick={this.closeModal}>
            {t('SetBrowseConfigurationModal.cancel')}
          </Button>
          <Button onClick={this.saveConfiguration}>
            {t('SetBrowseConfigurationModal.saveBrowseConfiguration')}
          </Button>
        </div>
      </div>
    );
  };

  getModal = () => this.modalRef.current;

  getTitle = () => {
    const { t, propertyName } = this.props;
    const name = propertyName ? ` - ${propertyName}` : '';

    return `${t('SetBrowseConfigurationModal.title')} ${name}`;
  };

  matchItemById = (matchId, { id }) => matchId === id;

  selectedTab = () => {
    const { activeTab } = this.state;
    return activeTab === '1' ? 'browseConfig' : 'headerLinks';
  };

  onAddNewItem = ({
    link,
    openInNewWindow,
    text,
    loggedIn = true,
    loggedOut = true,
    showOnMobile = true,
    showOnDesktop = true
  }) => {

    this.setState(prevState => {
      const state = prevState[this.selectedTab()] || [];
      state.push({
        id: this.generateId(),
        link,
        open_in_new_window: openInNewWindow,
        text,
        logged_in: loggedIn,
        logged_out: loggedOut,
        show_on_mobile: showOnMobile,
        show_on_desktop: showOnDesktop
      });

      return { [this.selectedTab()]: state };
    });
  };

  onItemEdit = ({ link, openInNewWindow, text, loggedIn, loggedOut, showOnMobile, showOnDesktop }, id) => {
    this.setState(prevState => {
      const state = prevState[this.selectedTab()] || [];
      const index = state.findIndex(this.matchItemById.bind(this, id));

      if (index !== -1) {
        state[index] = {
          id,
          link,
          open_in_new_window: openInNewWindow,
          logged_in: loggedIn,
          logged_out: loggedOut,
          text,
          show_on_mobile: showOnMobile,
          show_on_desktop: showOnDesktop
        };
      }

      return { [this.selectedTab()]: state };
    });
  };

  removeItem = (id) => {
    this.setState(prevState => {
      const state = prevState[this.selectedTab()] || [];
      const index = state.findIndex(this.matchItemById.bind(this, id));
      if (index !== -1) {
        state.splice(index, 1);
      }
      return { [this.selectedTab()]: state };
    });
  };

  onOrderChange = (items) => this.setState({ [this.selectedTab()]: items });

  renderLinks = ({
    link,
    open_in_new_window,
    text,
    logged_in = true,
    logged_out = true,
    show_on_mobile = true,
    show_on_desktop = true
  }, index) => {
    const { t } = this.props;
    const data = [{
      name: t('SetBrowseConfigurationModal.displayText'),
      key: 'text',
      value: text
    }, {
      name: t('SetBrowseConfigurationModal.navigationLink'),
      key: 'link',
      value: link
    }, {
      name: t('SetBrowseConfigurationModal.openInNewWindow'),
      key: 'open_in_new_window',
      value: open_in_new_window
    }, {
      name: t('SetBrowseConfigurationModal.loggedIn'),
      key: 'logged_in',
      value: logged_in
    }, {
      name: t('SetBrowseConfigurationModal.loggedOut'),
      key: 'logged_out',
      value: logged_out
    }, {
      name: 'Show on mobile',
      key: 'show_on_mobile',
      value: show_on_mobile
    }, {
      name: 'Show on desktop',
      key: 'show_on_desktop',
      value: show_on_desktop
    }];

    return (
      <div className="BrowseItem">
        <div className="BrowseItemIndex">{index + 1}</div>
        <div className="BrowseItem-inner">
          <ul className='HL-List'>
            {data.map(({ key, name, value }) => {
              const isCheckbox = [
                'open_in_new_window',
                'logged_in',
                'logged_out',
                'show_on_mobile',
                'show_on_desktop'
              ].includes(key);

              return (
                <li key={name}>
                  <span className='HL-Title'>{name}</span>
                  <span className='HL-Value' title={value}>{isCheckbox ? <Checkbox checked={value} disabled={true} /> : value}
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    );
  };

  renderItemActions = (item) => {
    const { t } = this.props;
    return (
      <div className="Actions">
        <Button key="editBtn" onClick={this.showEditItemModal.bind(this, item)}>
          {t('SetBrowseConfigurationModal.edit')}
        </Button>
        <Button key="removeBtn" onClick={this.removeItem.bind(this, item.id)}>
          {t('SetBrowseConfigurationModal.remove')}
        </Button>
      </div>
    );
  };

  onTabChange = (tab) => { this.setState({ activeTab: tab }) };

  renderMainView = () => {
    const { browseConfig = [], headerLinks = [], activeTab } = this.state;

    const tabs = [{
      title: 'Dropdown links',
      view: (
        <BaseDragDropList
          items={browseConfig}
          onOrderChange={this.onOrderChange}
          renderItem={this.renderLinks}
          renderAdditional={this.renderItemActions} />
      )
    }, {
      title: 'Header links',
      view: (
        <BaseDragDropList
          items={headerLinks}
          onOrderChange={this.onOrderChange}
          renderItem={this.renderLinks}
          renderAdditional={this.renderItemActions} />
      )
    }];

    return (
      <TabsComponent activeKey={activeTab} onChange={this.onTabChange}>
        {tabs}
      </TabsComponent>
    );
  };

  saveConfiguration = () => {
    const message = this.props.t('SetBrowseConfigurationModal.savingDataMsg');
    this.setLoading(message);
    const { browseConfig, headerLinks } = this.state;
    let { propertyId, applyToAll } = this.props;
    const applyToPropertyId = applyToAll || propertyId;

    const data = {
      browse_config: browseConfig,
      header_links: headerLinks
    };

    editWhitelabelConfig(applyToPropertyId, data)
      .then(() => {
        displaySuccessNotification({
          duration: 3,
          message: this.props.t('SetBrowseConfigurationModal.setBrowseConfigurationSuccess')
        });
        this.closeModal();
      })
      .catch(err => {
        displayErrorNotification({
          duration: 3,
          message: this.props.t('SetBrowseConfigurationModal.setBrowseConfigurationFailure')
        });
        console.log(err);
        this.clearLoading();
      })
  };

  setLoading = (message) => this.getModal().setLoading(message);

  render() {
    return (
      <ModalDialog className="SetBrowseConfigurationModal"
        forwardedRef={this.modalRef}
        title={this.getTitle()}
        actions={this.getActions()}>
        <div className="SetBrowseConfigurationModal-inner">
          {this.renderMainView()}
        </div>
      </ModalDialog>
    );
  }
}

SetBrowseConfigurationModal.propTypes = {
  propertyId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  propertyName: PropTypes.string,
  applyToAll: PropTypes.string
};

export default withTranslation()(SetBrowseConfigurationModal);
