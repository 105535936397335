import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Input } from "antd";
import PropTypes from 'prop-types';

import ModalDialog from "../../../../../../../../../../components/modal/ModalDialog/ModalDialog";

import './BrowseConfigurationAddNewItemModal.scss';

/* istanbul ignore file */
class BrowseConfigurationAddNewItemModal extends Component {

  displayTextRef = React.createRef();

  modalRef = React.createRef();

  state = {
    id: '',
    link: '',
    openInNewWindow: false,
    text: '',
    loggedIn: true,
    loggedOut: true,
    showOnMobile: true,
    showOnDesktop: true
  };

  constructor(props) {
    super(props);
    const {
      id,
      link,
      open_in_new_window = false,
      text,
      logged_in = true,
      logged_out = true,
      show_on_mobile = true,
      show_on_desktop = true
    } = props.item || {};

    Object.assign(this.state, {
      id,
      link,
      openInNewWindow: open_in_new_window,
      text,
      loggedIn: logged_in,
      loggedOut: logged_out,
      showOnMobile: show_on_mobile,
      showOnDesktop: show_on_desktop
    });
  }

  componentDidMount() {
    this.focusDisplayText();
  }

  addItem = () => {
    const { onItemAdd } = this.props;
    const { id, link, openInNewWindow, text, loggedIn, loggedOut, showOnMobile, showOnDesktop } = this.state;
    if (onItemAdd) {
      onItemAdd({ link, openInNewWindow, text, loggedIn, loggedOut, showOnMobile, showOnDesktop }, id);
    }
    this.closeModal();
  };

  addNewItemIfDataValid = (event) => {
    if (event.key === 'Enter' && this.isDataValid()) {
      this.addItem();
    }
  };

  closeModal = () => {
    this.getModal().closeModal();
  };

  focusDisplayText = () => {
    this.displayTextRef.current.focus();
  };

  getActions = () => {
    const { t } = this.props;
    const { id } = this.state;
    const key = id ? 'saveItem' : 'addItem';
    return [
      <Button onClick={this.closeModal}
        key="cancelBtn">
        {t('BrowseConfigurationAddNewItemModal.cancel')}
      </Button>,
      <Button onClick={this.addItem}
        disabled={!this.isDataValid()}
        key="addItemBtn">
        {t(`BrowseConfigurationAddNewItemModal.${key}`)}
      </Button>
    ];
  };

  getModal = () => this.modalRef.current;

  getTitle = () => {
    const { id } = this.state;
    const { activeTab } = this.props;
    const selectedTab = activeTab === '1' ? 'Dropdown links' : 'Header links';
    const key = id ? 'editItemTitle' : 'addItemTitle';
    return this.props.t(`BrowseConfigurationAddNewItemModal.${key}`) + ' - ' + selectedTab;
  }

  isDataValid = () => {
    const { link, text } = this.state;
    return link && text;
  };

  onChange = (key, event) => {
    const { value } = event.target;
    this.setState({
      [key]: value
    });
  };

  onCheckboxChange = (key, event) => {
    const { checked } = event.target;
    this.setState({
      [key]: checked
    });
  };

  renderInputField = (label, key, ref) => {
    const value = this.state[key];
    return (
      <div className="InputField">
        <div className="Label">{label}:</div>
        <Input ref={ref}
          onChange={this.onChange.bind(this, key)}
          value={value}
          onKeyUp={this.addNewItemIfDataValid} />
      </div>
    );
  };

  renderCheckbox = (value, key, text) => {
    return (
      <Checkbox
        checked={value}
        onChange={this.onCheckboxChange.bind(this, key)}
      >
        {text}
      </Checkbox>
    )
  };

  render() {
    const { openInNewWindow, loggedIn, loggedOut, showOnMobile, showOnDesktop } = this.state;
    const { t } = this.props;
    return (
      <ModalDialog className="BrowseConfigurationAddNewItemModal"
        forwardedRef={this.modalRef}
        actions={this.getActions()}
        title={this.getTitle()}>
        <div className="BrowseConfigurationAddNewItemModal-inner">

          {this.renderInputField(t('BrowseConfigurationAddNewItemModal.text'), 'text', this.displayTextRef)}
          {this.renderInputField(t('BrowseConfigurationAddNewItemModal.link'), 'link')}

          {this.renderCheckbox(openInNewWindow, 'openInNewWindow', t('BrowseConfigurationAddNewItemModal.openInNewWindow'))}

          {this.renderCheckbox(loggedIn, 'loggedIn', t('BrowseConfigurationAddNewItemModal.loggedIn'))}

          {this.renderCheckbox(loggedOut, 'loggedOut', t('BrowseConfigurationAddNewItemModal.loggedOut'))}

          {this.renderCheckbox(showOnMobile, 'showOnMobile', 'Show on mobile')}

          {this.renderCheckbox(showOnDesktop, 'showOnDesktop', 'Show on desktop')}
        </div>
      </ModalDialog>
    );
  }
}

BrowseConfigurationAddNewItemModal.propTypes = {
  item: PropTypes.object,
  onItemAdd: PropTypes.func,
};

export default withTranslation()(BrowseConfigurationAddNewItemModal);
